<template>
  <a :href="link" class="button">
    <slot />
  </a>
</template>

<script>
export default {
  name: 'ZButton',
  props: {
    link: { type: String, default: '/' },
  },
};
</script>

<style lang="scss" scoped>
.button {
  display: block;
  background-color: $blue;
  color: $white;
  text-align: center;
  font-size: 1.8rem;
  padding: 2rem 0;
  max-width: 25rem;
  width: 100%;
  border-radius: 0.3rem;
  cursor: pointer;
  margin: auto;
  text-decoration: none;
  &:hover {
    background-color: lighten($blue, 10%);
  }
}
</style>
