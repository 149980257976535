<template>
  <footer>
    <img class="logo-background" src="@/assets/img/footer-logo-bg.svg" alt="footer-logo">
    <section class="sections">
      <article class="requestdemo">
        <span class="title">Schedule a Personal Walkthrough!</span>
        <ZButton link="/request">Request a Demo</ZButton>
      </article>
      <article class="support">
        <span class="title">Need support?</span>
        <span class="info">
          Monday to Friday 9:30am - 5:00pm EST<br>
          <a href="tel:+19542713294">954.271.3294</a><br>
          <a href="mailto:support@zinc.tech">support@zinc.tech</a>
        </span>
        <div class="rrss">
          <a href="https://www.instagram.com/zinc.tech/" target="_blank"><img src="@/assets/img/rrss/icon-instagram.svg" alt="instagram"></a>
          <a href="https://www.linkedin.com/zinctech" target="_blank"><img src="@/assets/img/rrss/icon-linkedin.svg" alt="linkedin"></a>
          <a href="https://www.youtube.com/" target="_blank"><img src="@/assets/img/rrss/icon-youtube.svg" alt="youtube"></a>
        </div>
      </article>
    </section>
    <section class="footer-policy">
      <div>
        <router-link to="/terms-of-service">Terms of Service</router-link>
        <router-link to="/privacy-policy">Privacy Policy</router-link>
      </div>
      <div>© All Rights Reserved Zinc Tech | 2022</div>
    </section>
  </footer>
</template>

<script>
import ZButton from '@/components/ZButton.vue';

export default {
  name: 'Footer',
  components: {
    ZButton,
  },
  setup() {
  },
};
</script>

<style lang="scss" scoped>
footer {
  width: 100%;
  margin-top: auto;
  background-color: $purple-light;
  color: $white;
  padding: 5rem 1rem 1rem;
  position: relative;
  z-index: 1;
  .logo-background {
    display: none;
    @include XL {
      position: absolute;
      display: block;
      bottom: 0;
      left: -10%;
      z-index: -1;
      height: 120%;
      max-width: 100%;
    }
  }

  .sections {
    display: grid;
    grid-template-columns: 100%;
    text-align: center;
    @include M {
      grid-template-columns: 50% 50%;
      gap: 2rem;
      max-width: 90%;
      margin: auto;
    }
    @include L {
      display: flex;
      margin-right: 3rem;
      margin-left: auto;
      justify-content: flex-end;
      & > article {
        margin-right: 15rem;
      }
      .requestdemo {
        margin-right: 10%;
      }
    }
    article {
      margin-bottom: 3rem;
      font-size: 1.4rem;
      line-height: 2rem;
      .title {
        font-size: 1.8rem;
        font-weight: bold;
        display: block;
        margin-bottom: 1.5rem;
      }
    }
  }
  .footer-policy {
    text-align: center;
    font-size: 1.4rem;
    div {
      margin: 0.5rem;
      & > a {
        margin: 0 1rem;
      }
    }
  }
  .rrss {
    img {
      height: 3.5rem;
      width: auto;
      margin: 3rem 1.5rem;
    }
  }
}
</style>
