<template>
  <header>
    <Navbar />
  </header>
  <main>
    <router-view/>
  </main>
  <Footer />
</template>

<script>
import Navbar from '@/components/Navbar.vue';
import Footer from '@/components/Footer.vue';

export default {
  components: {
    Navbar,
    Footer,
  },
};
</script>

<style lang="scss">
@import './styles/css/reset.css';
@import "./fonts/roboto/index.css";

*, *:before, *:after {
  box-sizing: border-box;
}

body, html {
  height: 100%;
  font-size: 10px;
}

a {
  &:link,
  &:visited,
  &:active,
  &:focus {
    text-decoration: underline;
    border-style: none;
    outline: none;
    color: $white;
  }
  &:hover {
    border-style: none;
    outline: none;
    text-decoration: underline;
    font-weight: bold;
  }
}

h2 {
  font-size: 2.8rem;
  line-height: 4rem;
}

b {
  font-weight: bold;
}

#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $black;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: auto;
  font-size: 1.6rem;
}
</style>
