<template>
  <nav>
    <div class="mobilenav">
      <div class="toggle" @click="handleOpen">
        <div class="hamburger" :class="{ close: Open }">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
    <div class="logo">
      <a href="/" class="link">
        <img src="@/assets/img/logo.svg" alt="logo" title="logo" />
      </a>
    </div>
      <div :class="{ items: true, open: Open }">
        <ul class="links">
          <!-- <li><router-link to="/">What is Zinc</router-link></li>
          <li><router-link to="/">Features</router-link></li>
          <li><router-link to="/">Experience</router-link></li> -->
          <li><a href="https://zinc.freshdesk.com/support/solutions" target="_blank">Support</a></li>
          <li class="nohover"><a href="https://app.zinc.tech/" target="_blank" class="login-button">Login</a></li>
        </ul>
      </div>
  </nav>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'Navbar',
  setup() {
    const Open = ref(false);

    const handleOpen = () => {
      Open.value = !Open.value;
    };

    return {
      handleOpen,
      Open,
    };
  },
};
</script>

<style lang="scss" scoped>
$navHeight: 10rem;
nav {
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  height: 6rem;
  width: 100%;
  font-size: 1.8rem;
  background-color: $purple;
  z-index: 2;
  margin: auto;
  transition: top 0.5s ease-out;
  @include M {
    height: $navHeight;
  }
  @include L {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .open {
    // display: block !important;
    visibility: visible !important;
    opacity: 1 !important;
    transition: opacity 0.5s ease-in-out;
  }

  .logo {
    position: absolute;
    top: 50%;
    left: $Gpadding;
    transform: translateY(-50%);
    @include M {
      left: 5rem;
    }
    @include L {
      left: 10rem;
    }
    & img {
      height: 3rem;
      width: auto;
      @include L {
        height: 5rem;
        width: auto;
      }
    }
  }

  .mobilenav {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 2rem;
    @include M {
      display: none;
      position: absolute;
      a {
        text-decoration: none !important;
      }
    }
    .toggle {
      width: 100%;
      cursor: pointer;
      height: 100%;
      .hamburger {
        display: float;
        float: right;
        span {
          display: block;
          width: 3.3rem;
          height: 0.4rem;
          margin-bottom: 0.5rem;
          position: absolute;
          right: 3rem;
          background-color: $white;
          border-radius: 0.3rem;
          z-index: 1;
          transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease;

          &:first-child {
            top: 35%;
            transform-origin: 0% 0%;
          }
          &:nth-last-child(1) {
            top: 50%;
            transform-origin: 0% 100%;
          }
          &:nth-last-child(2) {
            top: 65%;
            transform-origin: 0% 100%;
          }
        }
        &.close span {
          opacity: 1;
          transform: rotate(45deg) translate(-1px, -2px);
          background: $white;
        }
        &.close span:nth-last-child(2) {
          transform: rotate(-45deg) translate(-1px, 1px);
        }
        &.close span:nth-last-child(1) {
          opacity: 0;
        }
      }
      @include M {
        display: none;
      }
    }
  }

  .items {
    background-color: $purple;
    // display: none;
    visibility: hidden;
    color: $white;
    height: 100%;
    opacity: 0;
    @include M {
      visibility: visible;
      display: flex;
      opacity: 1;
      justify-content: flex-end;
      align-items: center;
      margin: 0 2rem;
      max-height: none;
      overflow-y: inherit;
    }
    a {
      color: white;
      text-decoration: none;
      &:hover, &:focus, &:visited {
        color: white;
        font-weight: normal;
      }
    }
    .links {
      background-color: $purple;
      padding: 2rem 0;
      @include M {
        padding: 0;
      }
      li {
        text-align: center;
        &:hover:not(.nohover) {
          background-color: rgba(black, 0.1);
        }
        padding: 1.2rem 0;
        @include M {
          height: $navHeight;
          line-height: $navHeight;
          padding: 0 2rem;
        }
      }
      @include M {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
}
.login-button {
  background-color: $blue;
  padding: 0.6rem 3rem;
  border-radius: 9rem;
  &:hover {
    background-color: lighten($blue, 10%);
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
